<script setup>
import { v4 as uuidv4 } from "uuid";

const props = defineProps({
  bigger: Boolean,
  checked: Boolean,
  disabled: Boolean,
  error: [String,Boolean],
  black: Boolean,
  fullClick: Boolean,
  looksLikeDisabled: Boolean,
  flex: {
    type: Boolean,
    default: () => false
  }
})

const emits = defineEmits(['change', 'update:checked']);
const size = computed(() => props.bigger ? 20 : 16);
const checked = computed(() => props.checked)
const uniqueId = "chk_" + useId();


const onChange = ($event) => {
  emits('change',$event.target.checked);
  emits('update:checked', $event.target.checked);
}

</script>

<template>
  <div class="flex items-start">
    <div :class="[{'opacity-75': looksLikeDisabled}, {'flex': flex}]" 
      class="relative"
    >
      <input type="checkbox"
             class="peer m-0 rounded-[3.5px] bg-white border border-azure disabled:border-gray-400 disabled:bg-gray-200 appearance-none cursor-pointer"
             :class="[
              {
                'w-4 h-4':!props.bigger, 
                'w-5 h-5':props.bigger, 
                'pointer-events-none': looksLikeDisabled,
                'border-error-red':error,
              }
              ]"
             :checked="checked"
             @change="onChange"
             :disabled="props.disabled"
             :looksLikeDisabled="props.looksLikeDisabled"
             :id="uniqueId"

      >
      <div class="hidden peer-checked:block absolute left-[-1px] pointer-events-none w-4 h-4 border border-transparent"
           :class="[{'top-[1px]':!props.bigger,'top-[-1px]':props.bigger}]">
        <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#0A66A8" stroke="#0A66A8" />
          <path d="M5.69471 7.28071C5.50611 7.09855 5.25351 6.99776 4.99131 7.00004C4.72911 7.00232 4.4783 7.10749 4.29289 7.29289C4.10749 7.4783 4.00232 7.72911 4.00004 7.99131C3.99776 8.25351 4.09855 8.50611 4.28071 8.69471L6.28071 10.6947C6.46824 10.8822 6.72255 10.9875 6.98771 10.9875C7.25288 10.9875 7.50718 10.8822 7.69471 10.6947L11.6947 6.69471C11.8769 6.50611 11.9777 6.25351 11.9754 5.99131C11.9731 5.72911 11.8679 5.4783 11.6825 5.29289C11.4971 5.10749 11.2463 5.00232 10.9841 5.00004C10.7219 4.99776 10.4693 5.09855 10.2807 5.28071L8.28071 7.28071C7.56661 7.99482 6.40882 7.99482 5.69471 7.28071Z" fill="white" />
        </svg>
      </div>
    </div>
    <label :for="props.fullClick ? uniqueId : null" class="font-extralight ml-1.5 overflow-ellipsis overflow-x-hidden"
         :class="[{'text-xs mt-0.5 text-gray-400':!props.bigger,'text-sm text-black':props.bigger, '!text-black': props.black,'cursor-pointer':fullClick}]">
      <slot />
      <div v-if="error" class="block pb-1 text-error-red ">{{ error }}</div>
    </label>
  </div>

</template>
